<template>
  <PropertyListing></PropertyListing>
</template>
<script>
import PropertyListing from "../components/PropertyListing.vue";
export default {
  name: "hotel_listing",
  components: {
    PropertyListing,
  },
};
</script>
